@media all and (max-width: 480px) {
  .cart {
    position: absolute;
    right: 15px;
    top: 35px;
    color: #fff;
    font-size: 13px;
    
  }

  .cart img {
    position: absolute;
    right: -3px;
    top: 6px;
    z-index: -1;
  }
 
  .cartImg{
    position: absolute;
    right: -3px;
    top: 6px;
    z-index: 100;
  }
  .cardText {
    position: absolute;
    left: 12%;
    right: 20.4%;
    top: 16.52%;
    bottom: 81.57%;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 17px; */
    color: rgba(31, 36, 33, 0.5);
  }

  .cardText1 {
    position: absolute;
    left: 12%;
    /* right: 20.4%; */
    top: 5.52%;
    bottom: 81.57%;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 17px; */
    color: rgba(31, 36, 33, 0.5);
  }

  .popupInner {
    padding-top: 20px;
    padding-left: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  .rectangle {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
    width: 330px;
    height: 180px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .rectangle1 {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    width: 330px;
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .Modal {
    position: fixed;
    bottom: -150vh;
    background-color: #fff;
    width: 100%;
    height: 55%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    padding: 0 12px 12px;
    transition: all 0.3s ease-out;
    z-index: 10;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .Modal.Show {
    bottom: 0;
  }

  .Close {
    cursor: pointer;
    float: left;
    margin-top: 20px;
    margin-left: 10px;
    /* position: absolute;
    left: 5%;
    top: 25%; */
    /* right: 37.5%; */
    color: rgba(0, 0, 0, 0.2);
    /* padding: 0;
    border: none;
    background: none; */
    /* font-size: 20px; */
  }
  .Close1 {
    cursor: pointer;
    float: right;
    /* margin-top: 10px; */
    margin-right: 30px;
    /* position: absolute;
    left: 5%;
    top: 25%; */
    /* right: 37.5%; */
    color: rgba(0, 0, 0, 0.2);
    /* padding: 0; */
    border: none;
    background: none;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
/* 
  .iconSize {
    position: absolute;
    left: 5%;
    right: 37.5%;
    color: #000000;
  } */

  .Overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
  }

  .Overlay.Show {
    display: block;
  }

  .alignBtn {
    width: 100%;
    margin: 0 auto;
    /* margin-top:15px; */
    height: 35px;
    /* border:1px solid black; */
  }

  .oval {
    margin-right: 4%;
    float: left;
    width: 20%;
    height: 30px;
    background-color: #fdfdfd;
    border: 1px solid #d5b038;
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
  }

  .oval:first-child {
    margin-left: 4%;
  }

  .rupee {
    border: none;
    background: none;
    outline: none;
    color: #d5b038;
    text-align: center;
  }

  .inputBtn {
    height: 40px;
    width: 150px;
    background: linear-gradient(0deg, #d4af37 26.67%, #ffd700 100%);
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .hr {
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    top: 350px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .divider {
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    top: 450px;

    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .amount {
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.102);
    border-radius: 10px;
  }

  .enterAmount {
    font-size: 12px;
    color: #000000;
  }

  .textBox {
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    /* display: inline-block; */
  }

  .divider1 {
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    top: 455px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .buyNow {
    background: linear-gradient(0deg, #d4af37 26.67%, #ffd700 100%);
    border-radius: 10px;
    color: #ffffff;
    height: 50px;
    width: 83%;
    border: 2px solid #d4af37;
  }

  .iconSize {
    position: absolute;
    left: 5%;
    right: 37.5%;
    /* top: 5%;
    bottom: 75%; */
    color: #000000;
  }
  

  .button {
    /* margin-left: 25px  !important;
    margin-right: 30px !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18;
    color: #ffffff;
    height: 40px;
    width: 120px;
    background: linear-gradient(0deg, #d4af37 26.67%, #ffd700 100%);
    border-radius: 10px;
    border: none;
  }

  .footer {
    position: relative;
    margin-top: 30px;
    height: 90px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #ffffff;
    box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.1);
  }

  .btnCenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .imageAlign {
    display: block;
    margin: 0 auto;
    padding-top: 40px;
  }

.personal_page {
  
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  /* bottom:0 !important; */
  transition: all 0.5s ease-out;
  /* z-index: 1; */
  /* border-top-left-radius: 25px;
  border-top-right-radius: 25px; */
  
}
.personal_row {
  padding-left: "1rem";
}

.bankDetails_page {
  
  position: absolute;
  width: 100%;
  height: 530px;
  left: 0px;
  top: 90px;
  background: #ffffff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  transition: all 0.5s ease-out;
  z-index: 10;
}

.profile_page {
  
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0px;
  bottom: 0 !important;
  background: #ffffff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  /* left: 0; */
  transition: all 0.5s ease-out;
  z-index: 10;
  /* border-top-left-radius: 25px;
  border-top-right-radius: 25px; */
  
}
.profile_image > img {
  /* position: absolute; */
  margin-top: 20px;
  width: 90px;
  height: 90px;
  float: left;
  /* left: 70px;
  top: 30px; */
  border-radius: 50%;
  border: 4px solid #d4af37;
  background:-moz-radial-gradient(all, #cba334 24%, #ffffff 88%);
}

.userActive {
  float: right;
  padding-top: 6%;
  margin-right: 6px;
}
.profile_option {
  position: absolute;
  /* width: 113px; */
  /* height: 17px; */
  left: 10px;
  top: 166px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  color: #6f6f6f;
}
.hr1 {
  position: absolute;
  width: 100%;
  height: 0px;
  left: 0px;
  right: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.profile_span {
 padding-left: 15px;
  font-size: 18px;
  color: #6f6f6f;
}
.profile_span1 {
  position: absolute;
  width: 200px;
  left: 60px;
  font-size: 18px;
  color: #037bfc;
}
.profile_icon {
  /* padding-bottom: 10px; */
  /* position: absolute; */
  padding-left: 30px;
  color: #6f6f6f;
}
.profileCardText {
  margin-top:150px;
  margin-bottom: 100px;
}
.divHr {
  width: 100%;
  height: 0px;
  left: 0px;
  right: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.personal_span {
  position: absolute;
  /* width: 200px; */
  right: 10px;
  font-size: 16px;
}

.personal_span2 {
  position: absolute;
  /* width: 200px; */
  right: 10px;
  left: 120px;
  font-size: 16px;
}
.personal_heading {
  position: absolute;
  left: 15px;
  font-size: 16px;
}
 
.btnStyle{
  font-weight: 600;
  font-size: 18;
  color: #FFFFFF;
  height: 50px;
  width: 100px;
  /* background:
    linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%); */
  border-radius: 10px;
  border: none;
}

.fixedFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.1);
}
.header-title{
  padding-top: 40px !important;
  /* padding: 20px !important; */

}
.head5{
  font-family: "Montserrat";
  font-size: 16px;
  text-align: center;
}
.wrapper{
  padding: 0 20px 30px;
}
.resendOtp {
  color: #666666; 
  font-weight:bold ;
  font-size: 14px ;
  border: none;
  outline: none;
  background-color:#ffffff;
}

.resendOtp.on {
  opacity: 0.3; 
  cursor:not-allowed;
}
.signinBtn {
  background-color: #D5B038;
  border: none;
  color: white;
  border-radius: 8px;
  width: 330px;
  height: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  outline: none;
}
.signinBtn.accept {
  opacity: 0.3;
  cursor: not-allowed;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: auto !important;
  margin: 1em;
}

 .react-pdf__Page{
  max-width: auto !important;
  /* width: 100% !important; */
  box-shadow: 0 0 20px rgba(191, 191, 191, 0.5);
  /* overflow: scroll; */
  margin: 1em;
} 

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
  /* max-width: auto !important; */
  /* width: 100% !important;
  height: 100% !important; */
}

button.withoutbtnback{ 
  border:1px solid #D5B038;
  border-radius:3px;
  margin:3px;
  height:30px;
  line-height:0px;
  color:#D5B038;
  font-size:13px;
  cursor:pointer;    
  font-family: 'Josefin Sans', sans-serif;
}

button.withoutbtnback:focus{  
   outline:0 !important;
}


button.btnbackground{
  background-color:#D5B038;
  border:1px solid #D5B038;
  border-radius:3px;
  margin:3px;
  height:30px;
  line-height:0px;
  color:#fff;
  font-size:13px;
  cursor:pointer;    
  font-family: 'Josefin Sans', sans-serif;
}

button.btnbackground:focus{  
  outline:0 !important;
}

.home-page {
  /* max-width: auto! important; */
  max-width: 100% !important;
  overflow: hidden;
  /* margin: 15px; */
  /* padding:20px; */

}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 4px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #000000;
  background-color: #e7e6e7;
  border-radius: 50%;
}

input[type="radio"]:checked + label:before {
  border: 2px solid #93e026;
  background-color: #93e026;
}

input[type="radio"]:checked + label:before {
  border: 2px solid #000000;
  background-color: #000000;
}

input[type="radio"]:checked + label {
  font-weight: 400px;
  font-size: 16px;
}

.emilabel {
  display: flex;
  align-items: center;
}

.wrapper1{
  padding: 0px 40px 20px 20px;
}

.wrapper{
  padding: 120px 40px 20px 20px;
}

.wrapper2{
  padding: 90px 40px 20px 20px;
}
.formWrapper {
  padding: 20px 35px 75px 35px;
}

.textStyle {
    font-family: "Montserrat";
    font-weight: "normal";
    font-size: 14px;
    color: #000000;
    padding-bottom: 5px;
}
.overlapText1 {
  position: absolute;
  opacity: 0.8;
  z-index: 1;
}
.overlapText2 {
  position: absolute;
  z-index: 2;
  text-align: end;
vertical-align:middle;
line-height: 8rem;
  color:#b62f2f;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: "bold";
  width: 8rem;
  height: 8rem;
  /* padding-top: 2.5rem; */
}


.overlapText3 {
  position: relative;
  /* position: absolute; */
  /* z-index: 3; */
  opacity : 0.4;
}
.overlapText4 {
  z-index: 1;
  /* position: absolute;
  z-index: 4; */
  text-align: end;
vertical-align: middle;
line-height: 8rem;
  color:#b62f2f;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: "bold";
  width: 8rem;
  height: 8rem;
  /* padding-top: 2.5rem; */
  opacity : 0.9;
  text-decoration: none;

}
.overlapText4:focus, .overlapText4:active, .overlapText4:visited{
  text-decoration: none;
  outline: none;
}

.overlapText5 {
  z-index: 1;
  /* position: absolute;
  z-index: 4; */
  text-align:end;
vertical-align: middle;
line-height: 8rem;
  color:#b62f2f;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: "bold";
  width: 8rem;
  height: 8rem;
  /* padding-top: 2.5rem; */
  opacity: 0.9;
}

.textStyle1 {
  font-family: "Montserrat";
  font-weight: "normal";
  font-size: 14px;
  color: #000000;
}

.fixedFooter1 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index:2;
}
.fixedHeader {
    position: fixed;
    background-color: #D5B038;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;                
}

#inputID::placeholder {
  color: "grey";
  opacity: 0.4;
}

  .card-shadow {
    background: #FFFFFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    margin: 20px;
    display: flex;
  }

  .star {
    min-width: 92px;
    min-height: 87px;
  }

  .card-cantainer {
    background: #FFF9E3;
    overflow: hidden;
    padding: 20px 0;
    padding-bottom: 100px;
  }

  .card-row {
    background: #FFF9E3;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
  }

  .card-circle {
    /* width: 77px;
    height: 77px; */
    margin-left: 20px;
    /* border-radius: 50%;
    background: #fff; */
  }

  .card-center {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 14px;
    margin-left: 30px;
    width: calc(100% - 57px);
    background: #FFF9E3;
    padding: 0 10px 10px 0;
    border-bottom: 1px solid #D5B038;
  }
  .card-right {
    margin-bottom: 0;
    background: #FFF9E3;
    padding: 0 20px 20px 0;
    border-bottom: 1px solid #D5B038;
  }

  .btn-yellow-large {
    background: linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%);
    border: none;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: block;
    height: 50px;
    width: 100%;
    padding: 10px 30px;
    margin: auto;
  }
  .btn-yellow-medium {
    background: linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%);
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: block;
    height: 50px;
    width: 150px;
    /* padding: 10px 80px; */
    margin: auto;
    border: none;
  }

  .btn-yellow1 {
    background: linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: block;
    height: 40px;
    width: 100px;
    /* padding: 10px 80px; */
    margin: auto;
    border: none;
  }
  .card-row h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 177.3%;
    color: #000000;
  }

  .padding-30 {
    padding: 30px;
  }
  .Collapsible__contentInner {
    position: relative;
    padding-top: 10px;
  }
  .Collapsible__trigger{
    display: block;
    position: relative;
    padding-right: 15px;
  }

  .Collapsible__trigger:after{
    display: block;
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: 1px;
    content: '';
    position: absolute;
    top: 7px;
    width: 9px;
    height: 9px;
    right: 10px;
  }

  .is-closed:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .is-open:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .card-center p{
    font-weight: 300;
  }
  .box-card {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 50px 20px 30px;
    text-align: center;
    margin: 40px 30px;
    position: relative;
  }
  .box-card:hover {
    border: 1px solid rgba(255 ,204, 16, 0.9);
  }

  .head2 h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    margin: 0;
  }

  .page-name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }

  .p-l-30 {
    padding-left: 30px;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-25 {
    padding-top: 25px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-t-35 {
    padding-top: 35px;
  }

  .p-t-40 {
    padding-top: 40px;
  }

  .p-l-10 {
    padding-left: 10px;
  }

  .p-r-10 {
    padding-right: 10px;
  }

  .box-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .direction-row {
    display: flex;
    flex-direction: row;
  }
  .maxWidth40{
    max-width: 40px;
    margin-right: 10px;
  }

  .yellow .MuiButton-label{
    color: #D4AF37;
    font-size: 16px;
    line-height: 150.1%;
  }

  .light .MuiButton-label{
    color: #979797;
    font-size: 16px;
    line-height: 150.1%;
  }
  .head2 p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
  }

.required{
  color:red;
  font-size: 22px
}
  .wrapper-box-card {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    padding-top: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */


    color: #333333;
  }

  .wrapper-box-card p {
    margin-bottom: 0;
    color: #979797;
  }

  .headRight {
    border-left: 1px solid #979797;
    width: 50%;
  }

  .headLeft {
    width: 49%;

  }

  .top_img {
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .edit_right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: #fff;
  }

  /* .edit_right i {
    font-size: 14px;
    line-height: 17px;
    color: rgba(35, 31, 32, 0.6);
    border:none;
    background-color: #fff;
  } */
  .p-t-0{
    padding-top: 0;
  }

  .select-box {
    border: none;
    border-bottom: 1px solid #dddcdc;
    outline: none;
    padding-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6F6F6F;
    border-radius: 0;
  }

  .select-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }

  .select-input {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #6F6F6F;
    border: 1px solid #DDDCDC;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 9px 30px;
  }

  .select-input-btm {
    border: none;
    border-radius: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 30px;
    color: #6F6F6F;
    border-bottom: 1px solid #DDDCDC;
    box-sizing: border-box;
  }
  .select-input-p {
    border: none;
    border-radius: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6F6F6F;
    padding: 0;
    border-bottom: 1px solid #DDDCDC;
    box-sizing: border-box;
  }
  .search-icon{
    position: absolute;
    left: 15px;
    top: 13px;
  }
  .search-input {
    border-radius:8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 42px;
    line-height: 17px;
    color: #C3C3C3;
    padding: 10px 10px 10px 45px;
    border: 1px solid #DDDCDC;
    box-sizing: border-box;
  }
  .right-top{
    position: absolute;
    right: 0;
    top:10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6F6F6F;
  }
  .left-top{
    position: absolute;
    left: 0;
    top:10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }

  .comments {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    margin-bottom: 0;
  }

  .confirmation {
    font-size: 12px;
    line-height: 15px;
    color: #6F6F6F;
    margin-bottom: 0;
    margin-top: .3rem;
  }

  .select-checked {
    align-items: start;
    margin-left: 0;
    position: relative;
    margin-right: 10px
  }
  .footer-btn-css{
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      height: 50px;
      width: 300px;
      background:
        linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%);
      border-radius: 10px;
      border: none;
  }
  .savingPlanCSS {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    background: #D5B038;
    border-radius: 10px;
    border: none;
    margin-bottom: 20px;
  }

  .price-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1F2421;
  }
  /* input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */

  .card-jewellery-box {
    /* padding-right: 20px;
    padding-top: 15px;
    padding-left: 20px;
    overflow: hidden; */
  }

  .card-jewellery-box p {
    margin-bottom: 5px;
  }

  .card-jewellery-box h5 {
    margin-bottom: 5px;
  }

  .card-jewellery-items {
    font-weight: 500;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    background: #D5B038;
    padding-top: 10px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    width: calc(33.33% - 5px);
    float: left;
    border-radius: 6px;
  }
  .card-jewellery-items-1 {
    font-weight: 500;
    margin-left: 5px;
    margin-right: 10px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    background: #D5B038;
    padding-top: 10px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    width: calc(33.33% - 55px);
    float: left;
    border-radius: 6px;
    height: 40px;
  }
  .card-jewellery-items-5 {
    font-weight: 800;
    margin-left: 5px;
    margin-right: 70px;
    font-size: 15px;
    
    text-align: center;
    color: #FFFFFF;
    background: #D5B038;
    padding-top: 10px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    width: 170px;
    float: left;
    border-radius: 6px;
    height: 40px;
  }
  .card-jewellery-three-1 {
    font-weight: 500;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 5px 5px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 120px;
    width: calc(33.33% - 24px);
    float: left;
    border-radius: 6px;
  }

  .card-jewellery-three-2 {
    font-weight: 500;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 5px 5px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    width: calc(33.33% - 24px);
    float: left;
    border-radius: 6px;
  }
  .card-jewellery-three-3 {
    font-weight: 500;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 5px 5px;
    /* box-shadow: 2px 2px 15px #E9EDEB; */
    margin-bottom: 20px;
    width: calc(33.33% - 5px);
    float: left;
    border:none;
    /* border-radius: 6px; */
  }
  .card-jewellery-three-2 .price-item1 {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color:  #000000;
  }
  .card-jewellery-three-3 .price-item1 {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color:   #000000;
  }
  .price-item1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }


  .card-jewellery-three {
    font-weight: 500;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 5px 5px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 10px;
    width: calc(33.33% - 24px);
    float: left;
    border-radius: 6px;
  }

  .card-jewellery-three .price-item {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #6F6F6F;
  }

  .img-box {
    /* padding: 0 10px 7px; */
  }

  .card-jewellery {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    width: calc(50% - 20px);
    float: left;
    border-radius: 6px;
    min-height: 170px;
  }
  .card-jewellery:hover {
    box-shadow: 2px 2px 15px #F4E17B;
  }

  .filter-icon {
    position: absolute;
    width: 90px;
    height: 90px;
    right: 10px;
    top: -90px;
  }

  .card-jewellery img {
    margin-bottom: 10px;
   max-width: 120px;
    height: 100px;

  }
  .allCategoryJewellary {
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      height: 50px;
      width: 300px;
      background:
      linear-gradient(0deg, #D4AF37 26.67%, #FFD700 100%);
      border-radius: 10px;
      border: none;
  }

  .card-cart-wrap {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    box-shadow: 2px 2px 15px #E9EDEB;
    margin-bottom: 20px;
    border-radius: 6px;
    min-height: 160px;
    position: relative;
    overflow: scroll;
  }

  .card-cart-wrap-blank {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    min-height: 160px;
    position: relative;
    overflow: scroll;
  }

  .card-cart-wrap-blank ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .card-cart-wrap-blank ul li {
    padding: 5px 0;
    margin: 0;
    overflow: scroll;
    list-style: none;
    font-size: 14px;
    color: #333;
  }

  .card-cart-wrap-blank ul li.seprator {
    border-bottom: 1px solid #DDDCDC;
    padding: 8px 0;
  }

  .card-cart-wrap-blank ul li.total {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0;
  }

  .card-cart-wrap-blank ul li .first-left {
    float: left;
  }

  .card-cart-wrap-blank ul li .second-left {
    float: right;
  }

  .card-cart-wrap .img-left {
    width: 50px;
    float: left;
    padding-right: 10px;
  }

  .card-cart-wrap .float-right-link {
    float: right;
  }

  .card-cart-wrap .float-left-section {
    /* float: left; */
    text-align: left;
  }

  .float-right-btn {
    float: right;
    padding-right: 10px;
    display: inline;
    font-weight: 700;
    color: #D5B038; 
  
  }
  .float-left-btn {
    float: left;
    padding-left: 10px;
    display: inline;
    font-weight: 700;
    color: #D5B038; 
  }
  .card-cart-wrap .btn-yellow {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .card-cart-wrap .btn-yellow1 {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .card-cart-wrap .btn-round {
    background-color: #D5B038;
    border-radius: 5px;
    padding: 10px;
    display: flex;
  }

  .card-cart-wrap a {
    color: #fff !important;
    font-size: 20px;
    padding: 0 10px;
  }

  .p-b-0 {
    padding-bottom: 0;
  }

  .m-b-0 {
    margin-bottom: 0;
  }

  .card-locker {
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 20px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    box-shadow: 2px 2px 15px #E9EDEB;
  }
.expandble{
  text-align: left;
}.expandble .body-text{
  display: flex;
  padding-right:10px ;
  text-align: left;
}
 .body-text{
   padding: 5px  0;
 } .body-text input{
   margin-right: 10px;
 }
   .list{
     padding: 10px 0;
   }
   .heade{
     font-weight: 500;
     font-size: 16px;
     line-height: 20px;
     color: #000000;
   }
  .input-line{
    width: 100%;
    text-align: left;
    padding: 10px;
  }
   .input-line input.input{
     font-size: 14px;
     color: #333;
     border: none;
     width: 100%;
     padding: 5px;
     border-bottom: 1px solid #DDDCDC;
   }
  .closeBtns {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 20px;
    line-height: 20px;
    position: relative;
  }

  .closeBtns .cross {
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .card-wrap-blank {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 10px 20px 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    position: relative;
    display: flex;
    box-shadow: 2px 2px 15px #E9EDEB;
  }

  .card-wrap-blank ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .card-wrap-blank ul li {
    font-weight: 500;
    display: flex;
    margin: 0;
    padding: 7px 0;
    width: 100%;
    justify-content: space-between;
  }

  .card-wrap-blank ul li.seprator {
    border-bottom: 1px solid #DDDCDC;
    padding: 8px 0;
  }

  .card-wrap-blank .head {
    color: #D4AF37
  }

  .card-wrap-blanks {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    border-radius: 6px;
    position: relative;
    display: flex;
  }

  .card-wrap-blanks ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .card-wrap-blanks ul li {
    font-weight: 500;
    display: flex;
    margin: 0;
    padding: 7px 0;
    width: 100%;
    justify-content: space-between;
  }

  .closeBtn {
    padding: 10px;
  }

  .rounded-box {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    text-align: left;
    padding: 20px;
    margin-bottom: 20px;
  }

  .rounded-box h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 5px;
    color: #000000;
  }

  .rounded-box p {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .rounded-box h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
  }

  .rounded-box-shadow {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.102);
    border-radius: 10px;
  }
  .rounded-box-shadow h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #D5B038;
  }

  .useBtn {
    background: rgba(242, 242, 242, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #979797;
    padding: 10px;
    text-align: center;
    width: 80px;

  }

  .skipBtn {
    background: #D5B038;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    padding: 10px;
    text-align: center;
    width: 80px;
    border: none;
  }
  .tableSection{
    font-weight: 500;
    display: flex;
    margin: 0;
    padding: 15px 0;
    width: 100%;
    justify-content: space-between;
  }
  .tableSection input.input{
    width: 100px;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .card-blank {
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6F6F6F;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    border-radius: 6px;
    min-height: 160px;
    position: relative;
  }

  .card-blank ul {
    padding: 0;
    margin: 0;
  }

  .card-blank ul li {
    padding: 5px 0 5px 20px;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .card-blank ul li:before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: 6px;
    background: #C4C4C4;
    border-radius:50% ;
  }
  .card-blank h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
  }
  .card-product-title {
    margin-left: 10px;
    margin-right: 10px;
    background: #FFFFFF;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    border-top: 1px solid #DDDCDC;
  }
.thumbs-wrapper{
  display: none;
}
  .card-product-title h5 {
    font-weight: 600;
  }

  .card-product-title p {
    font-size: 12px;
    line-height: 15px;
    color: rgba(31, 36, 33, 0.5);
  }

  .card-product-title h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  } 
   .card-product-title h6 strong {
         font-weight: 500;
         font-size: 12px;
         line-height: 15px;
         color: #1DBF73;
  display: inline-block;
  padding: 0 10px;
  }
  .card-product-title h6 strike {
  display: inline-block;
  padding: 0 10px;
  }
  .use-locker-value {
    width: 325px;
    height: 150px;
    margin-top: 220px;
    margin-left: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.102);
    border-radius: 10px;
    clear:10px
    }

  .useLocker {
    margin-left: 3.5%;
    margin-right: 3%;
    float: left;
    width: 32%;
    height: 30px;
    background-color: #fdfdfd;
    border: none;
    /* border-radius: 5px; */
    text-align: center;
    box-sizing: border-box;
  }
  .useLocker1 {
    margin-left: 1%;
    margin-right: 1%;
    float: left;
    width: 15%;
    height: 30px;
    background-color: #fdfdfd;
    border: none;
    /* border-radius: 5px; */
    text-align: center;
    box-sizing: border-box;
  }

  .checkoutModal {
    position: fixed;
    bottom: -150vh;
    background-color: #fff;
    width: 100%;
    height: 70%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    padding: 0 12px 12px;
    transition: all 0.3s ease-out;
    z-index: 10;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .checkoutModal.Show {
    bottom: 0;
  }
}


